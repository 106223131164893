import React, { useState } from 'react';
import Slider from 'react-slick';

// Make sure to install these dependencies
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



function getSkillColor(skill) {
  const skillColors = {
    'C++': 'bg-blue-500',
    'Arduino': 'bg-red-500',
    'STM32': 'bg-purple-500',
    'AutoCAD': 'bg-pink-500',
    'Fusion 360': 'bg-pink-500',
    'Research': 'bg-green-500',
    'Circuit Designing': 'bg-orange-500',
    '3D Printing': 'bg-lime-500',
    'Structural Simulation': 'bg-teal-500',
    'Design Process': 'bg-teal-700',
    'Marketing': 'bg-pink-700',
    'Welding': 'bg-yellow-500', 
    'Physics': 'bg-yellow-600',
    'Math': 'bg-yellow-500', 
    'RX/TX Design': 'bg-teal-500',
    'Python': 'bg-indigo-500',
    'ReactJS': 'bg-blue-600',
    'NodeJS': 'bg-green-600',
    'ExpressJS': 'bg-pink-600',
    'VueJS': 'bg-green-700',
    'JavaScript': 'bg-yellow-800',
    'HTML': 'bg-red-600',
    'CSS/SCSS': 'bg-blue-700',
    'Flask': 'bg-black',
    'Django': 'bg-green-800',
    'Swift': 'bg-orange-600',
    'Flutter': 'bg-blue-800',
    'Firebase': 'bg-orange-700',
    'TailwindCSS': 'bg-teal-600',
    'C#': 'bg-purple-700',
    'SQL': 'bg-blue-900',
    'MongoDB': 'bg-green-900',
    'Heroku': 'bg-purple-800',
    'Vercel': 'bg-gray-700',
    'GitHub': 'bg-gray-800',
    'Employee Management': 'bg-indigo-700',
    'Funds Management': 'bg-pink-800',
    'Sponsorship': 'bg-orange-800',
    'Security': 'bg-red-700',
    'Electromagnets': 'bg-blue-300',
    'Battery Building': 'bg-yellow-700',
    'COOP': 'bg-indigo-500',
    'Motor Assembly': 'bg-gray-500',
    'Legal Regulations': 'bg-purple-600',
    'Partnership': 'bg-indigo-600',
    'Metal Work': 'bg-gray-700',
    'Super Capacitor': 'bg-lime-600',
    'Glass Work': 'bg-teal-700'

  };


  return skillColors[skill] || 'bg-gray-500';
}




const projects = [
  {
    id: 0,
    title: 'Electro Magnetic Roller Coaster',
    type: 'Project',
    skills: [
      'C++',
      'Electromagnets',
      'Arduino',
      'AutoCAD',
      '3D Printing',
      'Circuit Designing',
      'Structural Simulation',
      'Research',
      'Design Process',
      'Marketing',
      'Physics',
      'Math'
  ],
    description: 'This project took over 400 hours to make, working over 12 hours a day for 1 month straight. The goal of this project was to design a roller coaster that was better than its competition. Unlike most other roller coasters, this rollercoaster uses electromagnets, and lots of sensors to manage the cart speed, and ensure safety to ensure that multiple carts do not collide!',
    images: [
      'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExczhobDZsdWhldGVnOGExZjBsY3J0aXowaWprcXZoazJudWp6NjNqayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/wYasga1yeeevIEzghv/giphy.gif',
      'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExeHFiNTIwNDY1bmQ0dzFtbmNiMGU5czE0eTY2b2wwMnIxd3hzZXlmayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/SOFqToeeYxa81TSjlI/giphy.gif', 
      'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExdWZ1ZHVvMjMzZ213ZTNvbWFhdnEzbmh5Zjh0aGw0dmQ5aGx2dmttcSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/k0UmwgqTMHQJmkvz6M/giphy.gif',  
      'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExMDBvYnBoNjA2aW16ZzNtYmhrbzByZTBndGs2ZmMwdGpwdjN5Nmd1bSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/9Xfzdur4njQy4K4Amb/giphy.gif',
      'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExNnB2anZyeWVobWM1YWdncTh5dmJwOGQ2dW44YXhqYXpjaHFjcTEwNyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/HdMjGNEW0qfOFpCBUK/giphy.gif',
      'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExN2NvOHc4N2l4eXJoeHQ4eWk5engxczI5bnY2Zzl4NDBmaHZ2b21vOSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Vh1ZwyOJqsozGJsO2E/giphy.gif',
      'https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExM2hybGczbXZubnIyaGdvcWRkOXY5Mm1xZHN6bXp3bDIyamQ5c3F1ayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/88Bc3XSkDOxucwoPNh/giphy.gif'
    ],

    documentationLink: 'https://docs.google.com/document/d/1dC8zbiMPgMlWTLRu3BNwyuPRkUP-buGYwB-YgWwjnsQ/edit',
    presentationLink: 'https://docs.google.com/presentation/d/1IM775wLAwZbe4HVxWJb7Cz_V9-9pgnbqZoIW7kUkzQc/edit',
  },
  {
    id: 1,
    title: 'Quick-Chat.ca',
    website: 'Quick-Chat.ca',
    type: 'Business',
    skills: [
      'ReactJS',
      'JavaScript',
      'CSS/SCSS',,
      'Firebase',
      'TailwindCSS',
      'Heroku',
      'GitHub',
      'Employee Management',
      'Security'
  ],
    description: "Quick-Chat.ca is designed to be a versatile and intuitive chat platform. My emphasis during its development was on simplicity and functionality, ensuring that users could engage in seamless communication without the complexities often associated with modern chat applications.",
    images: [
      'https://images-ext-1.discordapp.net/external/e6z_SZy0RZkyOuVpePbgdZeVjje8h505TCjbGnf_akA/https/media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbzFtaDA0dmNlOXFzdXE4ZDZtNmUzcnhwNXV2d3NhcXh4MXAyNTU0NCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/y4M3hfIArOFrnaf2Bw/giphy.gif?width=720&height=405'
    ],
  },
  {
    id: 2,
    website:'AffordableEbikes.ca',
    type: 'Work Experince (Job)',
    skills: [
      'AutoCAD',
      '3D Printing',
      'Research',
      'Circuit Designing',
      'Design Process',
      'Motor Assembly',
      'Battery Building',
      'COOP'
  ],
    title: 'AffordableEbikes.ca',
    description: "As a Quality Control and Design Engineer in Lithium Battery Systems, I conducted rigorous quality assurance testing on battery cells, ensuring optimal performance and safety. I designed and assembled custom lithium battery packs, utilizing SolidWorks, 3D printing, and CNC plasma cutting, while integrating robust safety measures. Additionally, I diagnosed and resolved complex issues within battery packs and collaborated within cross-functional teams to design and build high-end electric bicycles.",
    images: [
      'https://previews.dropbox.com/p/thumb/ACasRG3zkxyuzAHjKn5FjCGvf6QgCfKb2j-zoLNuYPy6s7OvlSO-ecm-SwcAMxqX2tXmtR9LQ8Kez4RzmoYX8kZok3R7IUyjeYCHYnmhAv-I26dsvmIUb1_XXApkRx9YyCfm0Sq-pI_sqMltUuS0oRVoSmwGsARshL0QEso9rEI0qgXEx7YCkeMPOu5zJZAl6Q-VwXRCJ0fH89o5xNtdst-gc23IMCAWrES1IoqXmG3tMYdxBKSilAPM5vds-llpR9xxBwobLWHzd3e_et2SaOWYg-CIjuSCanOTKz4BLMxBQ5WvnHNgd6COEGfmxc0UErzix1aLDDXiyFTNXqDQlEdREQzZ23onP1h_EbnilWp4DQ/p.jpeg',
      'https://previews.dropbox.com/p/thumb/ACa2pMRzaieTbYjxuIJUxJaKkbfKc35zAYF04EhOD_oybeu3JP2tGuYPQNDKEfdy5K53lu7qx9MFOF_w7XzzMRkiaHhsaWuDQgEiP_CM3e0BT2UvIUUZ-34ozTlRtEgL4o8o3LSFP8rn3tk_xEYXa6gjl_CraBJXnW0R4UjRick2dpC4ymPAinY42rSFt42OcvdzSgPJBzhD7KrcaZ5AI4d3vMBVSosy10PEbgoUjkWmXHoj53kX_L-0kosNqHowEhAJEQKhAnHs16Dp54ZijrZ4MPDwikV_KxzQNsMbMnDpSeaSwx2BkLPxhIElcEhdjT7gWnl_iCjpeLeO2RDimVXd/p.png'
    ],
  },
 
  {
    id: 3,
    title: 'Arduino Drone',
    type: 'Project',
    skills: [
      'Physics',
      'Math',
      'C++',
      'Arduino',
      'AutoCAD',
      '3D Printing',
      'RX/TX Design',
      'Circuit Designing',
      'Research',
      'Design Process',
      
  ],
    description: "I designed 2 Arduino Drones from scratch, all the code, PID, scematics, and 3D models. I spent over 3 years on this projects, starting in grade 8/9, but completing it in grade 12. I will am continuing this project and will be adding many add-ons. I am currently working on a system where the drone can lock-on a target and follow it (just using C++).",
    images: [
      'https://media.giphy.com/media/4RlKgXUtdktmdGYrQt/giphy.gif'
    ],

    documentationLink: 'https://github.com',
  },
  {
    id: 4,
    website:'Site4u.ca',
    title: 'Site4u.ca',
    type: 'Business/Non-Profit',
    skills: [
      'Python',
      'ReactJS',
      'NodeJS',
      'ExpressJS',
      'VueJS',
      'JavaScript',
      'HTML',
      'CSS/SCSS',
      'Flask',
      'Django',
      'Swift',
      'Flutter',
      'Firebase',
      'TailwindCSS',
      'C#',
      'SQL',
      'MongoDB',
      'Heroku',
      'Vercel',
      'GitHub',
      'Employee Management',
      'Funds Management',
      'Sponsorship'

  ],
    description: "My approach with Site4u.ca has always been to offer more than just technical services; it's about building trust and relationships. We offer our expertise at no cost, ensuring that budget constraints never hinder one's digital aspirations. Our team, comprised of experienced developers, designers, and content creators, works diligently to deliver a product that not only meets but exceeds client expectations.",
    images: [
     'https://media.giphy.com/media/SSLH8ExkypjzGZFerf/giphy-downsized-large.gif'
    ],
  },
  {
    id: 5,
    title: 'G BIKES - Electric Bikes',
    type: 'Project',
    skills: [
      'C++',
      'Welding',
      'Arduino',
      'AutoCAD',
      '3D Printing',
      'Circuit Designing',
      'Structural Simulation',
      'Research',
      'Design Process',
      'Marketing',
      'Physics',
      'Math',
      'Battery Building',
      'Motor Assembly',
      'Legal Regulations',
      'Sponsorship',
      "Partnership",
      `Metal Work`,
  ],
    description: 'The G-Bike project focuses on designing an affordable and adaptable eBike solution that can compete with high-end electric bicycles while remaining accessible to a broader audience. The design includes various motor configurations and safety features, using cost-effective materials like car alternators and customized components. The G-Bike system is versatile, easily modifiable, and aims to provide an efficient and practical commuting option, all while ensuring that it meets legal requirements and safety standards.',
    images: [
     'https://media.giphy.com/media/hqcSPw5IWo2gUbPWyO/giphy-downsized-large.gif'
    ],

    documentationLink: 'https://docs.google.com/presentation/d/1EcJOeUOjXrDylkGZBoH7xvoSgHXaeK8NzdJ-808E35Q/edit?usp=sharing',
    presentationLink: 'https://docs.google.com/presentation/d/17WPnRl0BBQ-3rR2lvvY_blaOo5w4OBuTEJwLAqhtBzk/edit?usp=sharing',
  },
  {
    id: 6,
    type: 'Project',
    skills: [
      'C++',
      'RX/TX Design',
      'Arduino',
      'Fusion 360',
      '3D Printing',
      'Circuit Designing',
      'Structural Simulation',
      'Research',
      'Design Process',
      `Metal Work`,
      'Physics',
      'Super Capacitor',
      'Marketing',
      'Legal Regulations'
  ],
    title: 'Super Capacitor RC',
    description: 'The final tech project involves creating an Arduino-based RC vehicle designed for law enforcement use. This RC vehicle is equipped with advanced features like FPV (First Person View) capability, autonomous driving, GPS tracking, and fast-charging supercapacitors. It is intended to assist in monitoring traffic, chasing vehicles, and spying on illegal activities while maintaining a low profile. The project focuses on affordability, scalability, and functionality in various weather conditions, making it a practical tool for law enforcement agencies. The RC vehicle can quickly recharge using solar-powered stations and operate silently at lower speeds, offering a safer and more efficient alternative to traditional police pursuits.',
    images: [
     'https://media.giphy.com/media/45FQnOw8t777cOAcFW/giphy-downsized-large.gif'
    ],

    documentationLink: 'https://docs.google.com/document/d/1tGoP2SqroFpqHJakd51FqPsRqde1R_v0tNgUhs0sT3M/edit?usp=sharing',
    presentationLink: 'https://docs.google.com/presentation/d/1Evod0BbiVqyYBTm4cWWkowEXSguiH2vNumNgFMI5MKA/edit?usp=sharing',
  },
  {
    id: 7,
    title: 'The Door Stop',
    type: 'Project',
    skills: [
      'C++',
      'Electromagnets',
      'Laser Cutting',
      'Arduino',
      'Fusion 360',
      '3D Printing',
      'Circuit Designing',
      'Research',
      'Design Process',
      'Marketing',
      'Math'
  ],
    description: "From the idea to the final physical product, I compeleted and engineered this device within 2 weeks! The goal of this was to remove the traditional door stop. This doorstop utilizes an electromagnet and a wireless controller (ft. NRF24L01+). There are sensors that detect the door being pulled, and the firealarm, and in return shut off the electromagnet to ensure that it doesn't get damaged.",
    images: [
      'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExb2czbjQ1MmM0cjA2cGNpbmRsdHZnam5nc2R0b3N2bDNzcjFlN2VkcSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/PZb2L3s42NnhKuCQTW/giphy.gif',
      'https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExem1pY2JydWVmNzBpYzhtMGg5cnF3eWJoamFiOHRleXhmd3hjbTh2bSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/8cyWdpADqEl8UfCtHA/giphy.gif', 
      'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExamVraHVmejNzbzM3eHB2dXR2YWJxNzBsdTJ2b3FlaGg5YzU2cDRqNiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/DmfLX9fN0BCkEDMQXx/giphy.gif'
    ],

    documentationLink: 'https://docs.google.com/document/d/1rvO58dIji8akhgMWiy5xkZzZAhWCDkLI/edit?usp=sharing&ouid=105838316535286379404&rtpof=true&sd=true'
  },
 
  {
    id: 8,
    skill:[
      'Employee Management',
      'Funds Management'
    ],
    type: 'Business',
    skills: [
      'Python',
      'ReactJS',
      'NodeJS',
      'ExpressJS',
      'VueJS',
      'JavaScript',
      'HTML',
      'CSS/SCSS',
      'Flask',
      'Django',
      'Swift',
      'Flutter',
      'Firebase',
      'TailwindCSS',
      'C#',
      'SQL',
      'MongoDB',
      'Heroku',
      'Vercel',
      'GitHub',
      'Employee Management',
      'Funds Management',
      'Sponsorship'

  ],
    title: 'SigmaCoders.ca',
    website: 'www.sigmacoders.ca',
    description: "Sigma Coders offers a comprehensive tutoring solution for programming, dedicated to ensuring your success in any programming path. Our mission is to provide top-quality course material and teach industry practices used by leading tech companies like Google. Whether you're learning for school, university, or work, we guarantee you'll succeed. Additionally, you can learn to develop websites, games, and applications. Upon completing a programming language, you'll receive a certificate of completion, which can open doors to volunteer positions with our partner companies (conditions apply).",
    images: [
      'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExejhrN3FkY2Jmb3c4cGV6MGg4cnI2OHA0dDltb2dsazZueGJ3YTdtbCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l9gNopPvlJyZTaahVU/giphy.gif'
      
    ],
  },
  {
    id: 9,
    type: 'Project',
    skills: [
      'STM32',
      'C++',
      'Fusion 360',
      '3D Printing',
      'Design Process',
      'Circuit Designing',
      'Glass Work'

  ],
    title: 'Medicine Dispenser',
    description: "The project is an Automated Medicine Dispenser designed to simplify medication management, reduce errors, and ensure accurate dosages. It's user-friendly, affordable, and focuses on enhancing safety and efficiency in caregiving facilities. The system automates dispensing, prioritizes data security, and minimizes documentation errors, making it ideal for both caregivers and patients.",
    images: [
      'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExaXhkYnU1aTc5MjhtenFncDdtMWliZWhvN2xlc2Z5YTYyYWJyenFrZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/0RF3pkxrQmL8BjobO6/giphy-downsized-large.gif',
      
    ],

    documentationLink: 'https://docs.google.com/document/d/1AXm6MXt88W2IwFblYiAQIP9xIVRedTrTXMW2-JtnJ58/edit?usp=sharing'
  },
  {
    id: 10,
    type: 'Sports',
    title: 'ROPSSAA-Badminton',
    description: "After countless hours of dedication, my partner and I competed in numerous badminton tournaments, consistently finishing in the top three. We were proud to secure third place in ROPSSAA. It was an incredible experience, and I look forward to continuing my journey in badminton.",
    images: [
      'https://i.ibb.co/b5PDxtM/badminton.jpg',
    ],
  },
  {
    id: 11,
    type: 'Sports',
    title: 'OFSAA-Tennis',
    description: "After countless hours of dedication, my partner and I competed in the ROPSSAA tournament, securing 2nd place, just behind the top spot. This achievement qualified us for the OFSAA tournament, where we proudly represented our team.",
    images: [
      'https://i.ibb.co/3hX35mc/tennis.jpg'
    ],
  },
  {
    id: 12,
    title: 'CNML',
    type: 'Award',
    skills: [
      'Math'
    ],
    description: "After competing in numerous events, I achieved a top 25 ranking in the CNML league (Canada). Notably, this competition was completed by me in person.",
    images: [
      'https://previews.dropbox.com/p/thumb/ACYTOVMwUlZkmp-I5M9lGe3Lt1jOpFGzoL3MkTa2UHpdLUErIdMccKOG7zjTgxggp4RhE7vR9PNggy0dP3zjHK9bsYzY3zzhso9Fdm-xtVNWj7zKajqhPUVDnQ7zSmRRNixDWdns38sin3SZ-TIJryUWmxR783e7AxkMfB0cao1uI8LdSUVUAThvfaFZHVg8U56b9E0b_tKMfHX64tVHBAMtnNc2QU8pPR_J1zPAOwkl0bRfxkzKScTokZ8eQBIcoC8B1DIqA2eEuhWSAlC3fY6Z123PQJMlHBeFOp-ZYNuXjOOzi_dxK_B2kdXoIL1vYZA3gpzFtKwsqMnpAHf6BoQK/p.png?is_prewarmed=true'
    ],
  },
  {
    id: 13,
    title: 'Emergency Shelter',
    description: "In grade 10, I designed an affordable emergency shelter for global disaster relief. I focused on key principles like budgeting, packaging, safe water filtration systems, and more to create a practical and effective solution.",
    images: [
      'https://previews.dropbox.com/p/thumb/ACaPqnx-9x-dS3tG4z-jJMiKnOGqZ9vadgwMLt5J3IpRSYqnvdgY6zXAEYBnm6i8vBj5x_VJQIT071GvGWXTV7KymtmVqcyoCAqlJQpZpjENevbSwt25q2fU0o_iKeJ7XPuAmHq03NINBrdQVtdZPQDfpZWpP-qgEd3l6aIDsY7pR078D0SYZEsc8AOLBTGEi8Rvlhucx_nUGFBZFOlEIwcQEUcaUX7tVEHPwkgzL537-wD-OaYFrF2jLRu5roZXNCGjagO-EhPkETaQSZrMlHAzJvYDMDxOfBceWmtk-05-x1C8Cjv1imoBk1CcxXEaUms/p.jpeg'
      // 'https://i.ibb.co/K5DGpZn/IMG-1274.jpg'
    ],
    skills: [
      'Fusion 360',
      'Design Process',
  ],

    documentationLink: 'https://drive.google.com/file/d/1r8quWzW6LQ1cQG9mNnz6UX2oM-qXFlH8/view?usp=sharing',
  },
  {
    id: 14,
    type: 'Work Experince (Job)',
    skills: [
      'ExpressJS',
      'JavaScript',
      'HTML',
      'CSS/SCSS',,
      'Firebase',
      'Vercel',
      'GitHub',
      'Security',
      'COOP'
  ],
    title: 'Consumer Auto Parts',
    description: "Redesigned and developed a main website with admin control for easy updates. Created a hidden Kijiji-like platform for auto shops to sell parts directly, with features like ads, negotiation, and enhanced security to protect sensitive data.",
    images: [
      'https://i.ibb.co/z45TDRn/unnamed.jpg'
    ],
  },
  {
    id: 15,
    type: 'Sports',
    title: 'Life Guarding/ Competitive Swimming',
    description: "I competed in numerous swimming competitions across Ontario as part of the Barracuda swim team, earning second place in a 100m medley event.",
    images: [
      'https://i.ibb.co/4RC0gDn/19897286-6079318539454-8904247110539411456-n.jpg'
    ],
  },
  {
    id: 16,
    title: 'Sci-Tech Academic Committee Member',
    description: "A student-led committee that represents, promotes and features the Science & Technology Regional Program at Chinguacousy Secondary School.",
    images: [
      'https://assets-global.website-files.com/653bd524d3e7741f9b4ccb12/6553c55310b1b3f75b31f1d4_Copy%20of%20STAC%20final%20logo%20(1)%20(2).png'
    ],
  },
  {
    id: 17,
    title: 'ECO Blocks',
    description: "Designed and developed an innovative theme park ride prototype, focusing on realism, interactivity, and safety. The ride includes features like motorized spinning, adjustable angles, and mechanical assistance, making it both fun and functional.",
    images: [
      'https://i.ibb.co/SrG1zRm/ecoblocks.png',
'      https://i.ibb.co/B27wSjJ/ecoblock.png'
    ],
    skills: [
      'Fusion 360',
      'Design Process',
  ],
    documentationLink: 'https://docs.google.com/document/d/1dC8zbiMPgMlWTLRu3BNwyuPRkUP-buGYwB-YgWwjnsQ/edit',
  }
  // ... additional projects
];

export default function ProjectsSection() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [imageLoaded, setImageLoaded] = useState({});

  const handleImageLoaded = (id) => {
    setImageLoaded(prevState => ({ ...prevState, [id]: true }));
  };

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true, 
    centerPadding: '60px', 
    autoplay: true, 
    autoplaySpeed: 2000, 
  };

 
  const imageHeight = "h-64"; 

  // Button styling
  const buttonBaseStyles = "inline-block font-bold py-2 px-6 rounded transition duration-300 ease-in-out";
  const buttonPrimaryStyles = "bg-blue-600 text-white hover:bg-blue-700 hover:shadow-lg active:bg-blue-800";
  const buttonSecondaryStyles = "bg-purple-600 text-white hover:bg-purple-700 hover:shadow-lg active:bg-purple-800";



  return (
    <div className="bg-gray-800 min-h-screen text-white py-12 px-4">

        <div className="my-8 flex justify-center">
          <div className="w-1/2 h-0.5 bg-gradient-to-r from-transparent via-gray-500 to-transparent"></div>
        </div>



      <h2 className="text-3xl font-extrabold text-center text-transparent bg-gradient-to-r from-orange-400 via-pink-500 to-purple-600 bg-clip-text sm:text-5xl mb-8">
        Projects/ Awards/ Experiences
      </h2>




      <div className="container mx-auto px-4">
        {/* ... existing code for the title ... */}

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {projects.map((project) => (
            <div key={project.id} className="bg-gray-900 rounded-lg shadow-lg p-4 cursor-pointer">
              {/* Project image */}
              {project.images.length > 0 && (
                <>
                  {!imageLoaded[project.id] && (
                  <div className="animate-pulse rounded-lg bg-gray-700 h-64"></div>
                  )}
                  <img
                    src={project.images[0]}
                    alt={project.title}
                    className={`rounded-lg mb-4 ${imageLoaded[project.id] ? 'block' : 'hidden'}`}
                    onLoad={() => handleImageLoaded(project.id)}
                  />
              </>
              )}
              {/* Project title */}
              <h3 className={`text-lg font-bold`}>{project.title}</h3>
              {/* Project type label */}
              <p className="text-xs uppercase my-2">{project.type}</p>

              
              {/* Skills list */}
              {(project.skills==null) ? <div></div> :
                <>
                  <p className="text-xs uppercase mt-4 mr-2 py-1 pr-2 font-bold">Skills I Used:</p>
                  <div className="flex flex-wrap my-2">
                    {project.skills && project.skills.map((skill, index) => (
                      <p key={index} className={`text-xs uppercase mx-1 my-1 py-1 px-2 ${getSkillColor(skill)} rounded-full px-2`}>{skill}</p>
                    ))}
                  </div>
                </>
              }
              {/* Button to view project details */}
              <button
                onClick={() => setSelectedProject(project)}
                className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out hover:from-blue-400 hover:to-purple-500 hover:shadow-lg hover:shadow-blue-500/50"
              >
                View Details
              </button>
              {project.website && (
                <a
                  href={`https://${project.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 bg-gradient-to-r from-red-500 to-orange-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out hover:from-red-400 hover:to-orange-500 hover:shadow-lg hover:shadow-orange-500/50"
                >
                  Visit Website
                </a>
              )}
            </div>
          ))}


          
        </div>
      </div>

      {/* Modal for selected project */}
      {selectedProject && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center p-4 z-50">
          <div className="bg-white text-gray-800 rounded-lg p-6 max-w-lg w-grow z-50">
            <Slider {...settings}>
              {selectedProject.images.map((image, index) => (
                <div key={index} className="px-2">
                  <img src={image} alt={`Slide ${index}`} className={`w-grow ${imageHeight} object-cover rounded-lg`}  />
                </div>
              ))}
            </Slider>
            <div className="text-center mt-8">
              <h3 className="font-bold text-xl mb-4">{selectedProject.title}</h3>
              <p className="text-md mb-6">{selectedProject.description}</p>
              {(selectedProject.documentationLink==null) ? <></> :
                <a target="_blank"  href={selectedProject.documentationLink} className={`${buttonBaseStyles} ${buttonPrimaryStyles} mr-2`}>View Documentation</a>
              }
              {(selectedProject.presentationLink==null) ? <></> :
                <a target="_blank"  href={selectedProject.presentationLink} className={`${buttonBaseStyles} ${buttonSecondaryStyles}`}>View Presentation</a>
              }
              </div>
            <button
              className="mt-4 bg-gray-800 text-white py-2 px-6 rounded hover:bg-gray-600 focus:outline-none focus:ring"
              onClick={() => setSelectedProject(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}