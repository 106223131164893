import React, {useState} from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaSchool } from 'react-icons/fa';


import HighSchool from './../images/ching.jpg';
import MiddleSchool from './../images/centennial.JPG';
import SOM from './../images/som.png';

// Dummy data for the timeline
const educationData = [
  {
    year: '2023 - Present',
    degree: 'Bachelors Of Computer Engineering',
    school: 'University of Waterloo',
    imageUrl: "https://i.ibb.co/Wf4YsrW/IMG-5078.jpg",
    extracurriculars: [
      'Electrium Mobility Team Member', 
      'Site4u.ca - Continued',
      'Quick-Chat.ca - Continued',
      'SigmaCoders.ca - Continued',
      'Personal Projects (Can be found under Projects/Achievements Section)'
    ],
  },
  {
      year: '2019 - 2023',
      degree: 'Regional Science & Technology Diploma',
      school: 'Chinguacousy Secondary School',
      imageUrl: HighSchool,
      extracurriculars: [
        'Site4u.ca - I am the CEO/Founder @Site 4 u, a non-profit organization which helps develop and publish sites and apps for startup businesses internationally. Site4u has a team of 25+ engineers, students and individuals.',
        'Quick-Chat.ca - "I am the CEO/Founder at Quick Chat, an online platform to connect you and your friends. Quick Chat can also be used by teachers. Teachers can allow students to communicate in an easy to use chatting software, and can set tasks for students to complete." ',
        'SigmaCoders.ca - "Managing a large number of staff to ensure affordable computer science education."',
        'Personal Projects (Can be found under Projects/Achievements Section)',
        'Badminton Team',
        'Tennis Team',
        'Programming Head - FRC Robotics - "I am a head at FRC 3560, I am in charge of teaching and writing code to ensure that the FRC team this year wins!" ',
        'President - Chinguacousy CP(Competitive Programming) & AP Math Club',
        'President - Chinguacousy Web Development Club',
        'President - Chinguacousy Game Development Club',
        'Consumer Auto Parts - Software Engineer',
        'Game N Hack - CEO/Founder',
        'Spark Hackathon'
      ],
  },
  {
    year: '2019 - 2023',
    degree: 'Certificate of Distrinction',
    school: 'Spirit Of Math',
    imageUrl: SOM,
    extracurriculars: ['Spirit Of Math @ John Knox'],
  },
  {
  year: '2019 - 2023',
  degree: 'Regional Science & Technology Diploma',
  school: 'Chinguacousy Secondary School',
  imageUrl: MiddleSchool,
  extracurriculars: ['Robotics Club', 'Destination Imagination', 'Tech Stewardship', 'Film Team'],
  },
  // ... more data
];

export default function EducationTimeline() {
  const [selectedEdu, setSelectedEdu] = useState(null);

  return (
    <div className="bg-gray-800 text-white py-12">
      <h2 className="text-3xl font-bold text-center mb-8">Education Timeline</h2>
      
      <VerticalTimeline>
        {educationData.map((edu, index) => (
          <VerticalTimelineElement
            key={index}
            date={edu.year}
            iconStyle={{ background: 'rgb(33, 37, 41)', color: '#fff' }}
            contentStyle={{ background: 'rgb(75, 85, 99)', color: '#fff', borderRadius: '10px' }} 
            contentArrowStyle={{ borderRight: '7px solid  rgb(75, 85, 99)' }}
            icon={<FaSchool />}
          >
            <div className="text-center">
              <h3 className="vertical-timeline-element-title font-semibold text-xl mb-2">{edu.degree}</h3>
              <h4 className="vertical-timeline-element-subtitle font-medium text-lg mb-4">{edu.school}</h4>
              <img 
                src={edu.imageUrl}
                alt={`${edu.degree} at ${edu.school}`}
                className="mx-auto w-full h-auto object-cover rounded-lg my-4"
                style={{ maxHeight: '250px' }}
              />
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setSelectedEdu(edu)}
              >
                Learn More
              </button>
            </div>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>

      {selectedEdu && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className="bg-white text-gray-800 rounded-lg p-6 max-w-md w-full">
            <h3 className="font-bold text-xl mb-4">{selectedEdu.school} - Extracurriculars</h3>
            <ul>
              {selectedEdu.extracurriculars.map((activity, idx) => (
                <li key={idx} className="mb-2">{activity}</li>
              ))}
            </ul>
            <button
              className="mt-4 bg-gray-800 text-white py-2 px-6 rounded hover:bg-gray-600"
              onClick={() => setSelectedEdu(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}