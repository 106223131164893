import React, { useState, useRef, useEffect } from 'react';

const testimonials = [
  {
    name: "Myles Drake",
    readTime: "6 min read",
    title: "Affordable Ebikes (CO-OP)",
    imageUrl: "https://www.toastmasterspodcast.com/blog?uy_getimage+blogsmallImage-DVxzIx", 
    testimonial: "Gunvir is an exceptionally bright and talented young individual known for his sharp problem-solving skills and a natural aptitude for programming. His ability to understand complex concepts quickly and apply them effectively has made him stand out among his peers. This talent was particularly evident during his co-op at Affordable EBikes, a company focused on creating cost-effective and sustainable electric bicycles. Gunvir thrived in this dynamic environment, where he was able to put his analytical mind and technical skills to good use. \nAt Affordable EBikes, Gunvir contributed significantly to various projects. His role involved not only assisting in the design and development of the bikes' electrical systems but also working on ways to improve their efficiency and performance. Gunvir's ability to quickly diagnose issues and propose practical solutions earned him respect from his colleagues, making him a valuable team member. \n Beyond his technical prowess, Gunvir is also known for his sense of humor and easy-going nature. His colleagues at Affordable EBikes appreciated his ability to bring a light-hearted atmosphere to the workplace, even during stressful times. Gunvir’s knack for making people laugh and his positive attitude made him a joy to work with. He managed to strike a perfect balance between professionalism and friendliness, which fostered a collaborative and enjoyable work environment. \nGunvir's combination of intelligence, technical skill, and interpersonal qualities makes him an outstanding individual. His experiences at Affordable EBikes have not only honed his abilities but also demonstrated his potential to make significant contributions in the field of technology and beyond."
  },
  {
    name: "Mr. Mbengue",
    readTime: "3 min read",
    title: "High School Physics, Math, and French teacher",
    imageUrl: "https://i.ibb.co/jWz44Gt/1617842331975.jpg", 
    testimonial: "I had the pleasure of teaching Gunvir in my math class, where he not only excelled academically but also enriched the learning environment with his enthusiasm and deep understanding of mathematics. Gunvir's performance in my class was nothing short of exceptional. Achieving a perfect score of 100 is a rare feat, one that speaks volumes about his mathematical acumen, diligence, and dedication to the subject. His ability to grasp complex concepts with ease and apply them to various problems was remarkable. What truly set Gunvir apart, however, was his engagement and contribution to the class. He was always eager to participate, often leading discussions and helping clarify difficult topics for his peers. His insightful questions and solutions brought a level of depth to the classroom that inspired both his classmates and me. Gunvir's presence in the class was a delight. He brought a sense of curiosity and excitement for learning that was infectious. His consistent performance and proactive approach to learning make me confident that he will achieve great success in his future academic and professional endeavors."
  },
  {
    name: "Ms. Navea",
    readTime: "2 min read",
    title: "Head of Sci-Tech Regional Program",
    imageUrl: "https://i.ibb.co/tbg7gcP/1517441413219.jpg",
    testimonial: "In my technology class, Gunvir stood out as an exceptional student, demonstrating remarkable innovation and dedication. Over two years, his ability to understand complex technical concepts and transform them into impressive projects was truly remarkable. Gunvir's standout project, the Automated Door Stop, exemplified not just his technical skill but also his leadership and teamwork abilities. His work went beyond mere assignments; it enriched the entire class's learning experience. Beyond his technical expertise, Gunvir showed a relentless pursuit of knowledge and a willingness to collaborate and assist his peers. His presence in the classroom fostered a culture of mutual respect and collective learning. Gunvir is not just a student of technology; he is an innovator with the potential to make significant contributions to the field. I am confident in his ability to excel in any future endeavors."
  },
  {
    name: "Mr. Brown",
    readTime: "4 min read",
    title: "Masters Of Mathematics",
    imageUrl: "https://i.ibb.co/ZMwFRGR/1516904517807.jpg", 
    testimonial: "As Gunvir's physics teacher and coach in both badminton and tennis, I have had the unique opportunity to witness his exceptional growth and commitment across different disciplines. In the classroom, Gunvir's understanding of physics was not just academic. He applied these concepts practically, often going above and beyond the curriculum to explore more complex topics. His analytical skills and ability to grasp advanced theories were truly impressive. On the court, Gunvir displayed similar dedication and skill. His strategic approach to both badminton and tennis, combined with his physical agility, made him a formidable player. He demonstrated leadership, sportsmanship, and a continuous drive to improve his game, inspiring his peers and contributing significantly to the success of our school teams. Gunvir's unique blend of academic excellence and athletic prowess sets him apart. His ability to excel in both theoretical and practical aspects of learning is a testament to his well-rounded character and dedication. I am confident that these qualities will propel him to great success in his future endeavors."
  }
];

export default function TestimonialSection() {



  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const scrollRef = useRef(null);  // Create a reference to the scrollable container

  // Function to check if text is overflowing
  const checkOverflow = () => {
    if (scrollRef.current) {
      const isOverflow = scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
      setIsOverflowing(isOverflow);  // Set state to true if content is overflowing
    }
  };

  // Check for overflow when the component mounts or the testimonial changes
  useEffect(() => {
    checkOverflow();
  }, [selectedTestimonial]);

  // Function to scroll down the testimonial content
  const handleScrollDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ top: 100, behavior: 'smooth' });
    }
  };



  return (
    <div className="bg-gray-800 text-white py-12">
      <div className="my-8 flex justify-center">
      <div className="w-1/2 h-0.5 bg-gradient-to-r from-transparent via-gray-500 to-transparent"></div>
    </div>

    <h2 className="text-3xl font-extrabold text-center text-transparent bg-gradient-to-r from-green-400 via-green-500 to-blue-600 bg-clip-text sm:text-5xl mb-8">
      Testimonials
    </h2>

    <div className="container mx-auto px-4">
      {/* Flexbox grid for the testimonials */}
      <div className="flex flex-wrap justify-around items-stretch">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            onClick={() => setSelectedTestimonial(testimonial)}
            className="p-4 flex flex-col items-center bg-gray-900 rounded-lg shadow-lg min-h-full mt-4 mb-4" // Added mt-4 and mb-4 for vertical spacing
          >
            {/* Wrapper for both the image and text */}
            <div className="w-64 flex flex-col items-center">
              <img
                src={testimonial.imageUrl}
                alt={testimonial.name}
                className="w-64 h-64 object-cover rounded-lg mb-6"
              />
              <div className="text-center flex-grow w-full">
                <p className="font-semibold text-2xl text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600">
                  {testimonial.name}
                </p>
                <p className="text-gray-400 text-md">{testimonial.readTime}</p>
                <p className="mt-2 text-md">{testimonial.title}</p>
              </div>
            </div>
            <div>
              <button className="mt-4 bg-purple-600 text-white hover:bg-purple-700 hover:shadow-lg active:bg-purple-800 inline-block font-bold py-2 px-6 rounded transition duration-300 ease-in-out">
                Read More
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>

    {selectedTestimonial && (
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm flex justify-center items-center p-4">
        <div
          className="relative bg-white text-gray-800 rounded pt-6 pl-6 pr-6 w-full max-w-lg overflow-y-auto"
          ref={scrollRef}
          style={{ maxHeight: '75vh', height: 'auto' }}
        >
          <h3 className="font-bold text-xl mb-4">{selectedTestimonial.title}</h3>

          {/* Render testimonial text and split by newline */}
          <div className="text-lg">
            {selectedTestimonial.testimonial.split('\n').map((paragraph, i) => (
              <p key={i} className="mb-4">
                {paragraph}
              </p>
            ))}
          </div>

          {/* Sticky container for the arrow and close button */}
          <div className="sticky bottom-0 bg-white p-4 mt-4">
            {/* Show bouncing arrow only if the content is overflowing */}
            {isOverflowing && (
              <div className="flex flex-col justify-center items-center mb-2">
                <p className='text-slate-700'>Scroll To Read More</p>
                <button onClick={handleScrollDown} className="focus:outline-none">
                  <div className="animate-bounce">
                    <svg
                      className="w-8 h-8 text-gray-800"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            )}

            {/* Close button */}
            <div className="flex justify-center">
              <button
                className="bg-gray-800 text-white py-2 px-6 rounded hover:bg-gray-600 focus:outline-none focus:ring"
                onClick={() => setSelectedTestimonial(null)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
    </div>
  );
}